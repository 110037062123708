import {
	isDefined, restCall
} from '../../common/js/utilities.js';

class DownloadCertificates {
	constructor(){
		// Invoke the methods only if the page contains the Download Certificates shortcode
		if ( this.hasShortcode ) {
			try {
				this.getElements();
				this.getCertificatesType();
				this.getGroupsDropdown();
				this.getCoursesDropdown();
				this.getQuizDropdown();
				this.setGenCronJob();
			}
			catch ( e ){ console.log( e ); }
		}
	}

	getElements(){
		this.$elements = {
			filters: {
				typeWrapper: document.getElementById( 'ultp-download-certificates-filter-type' ),
				uo_cert_type: $('#uo_cert_type'),
				val_uo_cert_type: $('#val_uo_cert_type'),

				groupWrapper: document.getElementById( 'ultp-download-certificates-filter-group' ),
				uo_groups: $('#uo_groups'),
				uo_group_block: document.querySelector('.uo-groups-dropdowns-certificates'),
				val_uo_groups: $('#val_uo_group'),

				courseWrapper: document.getElementById( 'ultp-download-certificates-filter-course' ),
				uo_courses: $('#uo_courses'),
				uo_courses_block: document.querySelector('.uo-courses-dropdowns-certificates'),
				val_uo_courses: $('#val_uo_course'),

				uo_quizzes: $('#uo_quizzes'),
				uo_quizzes_block: document.querySelector('.uo-quizzes-dropdowns-certificates'),
				val_uo_quizzes: $('#val_uo_quiz'),

				uo_submit_btn_html: document.querySelector('.uo-generate-certificates-btn'),
				uo_submit_btn: $('#uo_generate_certificates_submit'),
				uo_current_user: $('#uo_current_user'),
				uo_download_url: $('#uo_download_url')
			}
		};
		this.$elements.filters.uo_cert_type.select2();
	}

	setGenCronJob(){
		this.$elements.filters.uo_submit_btn.on( 'click', ( e ) => {
			const cert_type = this.$elements.filters.uo_cert_type.val();

			document.getElementById('uo_generate_certificates_submit').disabled = true;
			this.$elements.filters.uo_submit_btn_html.classList.add('uo_clicked_btn');

			var group_id = this.$elements.filters.uo_groups.val();
			var course_id = this.$elements.filters.uo_courses.val();
			var quiz_id = this.$elements.filters.uo_quizzes.val();
			var download_url = this.$elements.filters.uo_download_url.val();

			const data = new FormData();
			data.append( 'action', 'set_cron_job' );
			data.append( 'certificate_type', cert_type );
			data.append( 'group_id', group_id );
			data.append( 'user_id', this.$elements.filters.uo_current_user.val() );
			data.append( 'course', course_id );
			data.append( 'quiz', quiz_id );
			data.append( 'download_url', download_url );

			data.append( 'val_cert_type', cert_type );
			data.append( 'val_group_id', group_id );
			data.append( 'val_course', course_id );
			data.append( 'val_quiz', quiz_id );

			fetch(uoCertificates.rest_url+'set-cron-job', {
				method: "POST",
				body: data
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if( data.success === true ) {
						document.getElementById('uo_certificatesdropdown').innerHTML = '<div class="uo-certificates-success">'+uoCertificates.uo_success_msg+'</div>';
					} else {
						document.getElementById('uo_certificatesdropdown').innerHTML = '<div class="uo-certificates-error">'+uoCertificates.uo_failure_msg+'</div>';
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}

	getCertificatesType() {
		// Listen to changes on certificate type
		this.$elements.filters.uo_cert_type.on( 'change', ( e ) => {
			this.clearAllErrors();

			// Hide children dropdowns
			this.uoHideBlock( this.$elements.filters.uo_group_block );
			this.uoHideBlock( this.$elements.filters.uo_courses_block );
			this.uoHideBlock( this.$elements.filters.uo_quizzes_block );
			this.uoHideBlock( this.$elements.filters.uo_submit_btn_html );

			const cert_type = this.$elements.filters.uo_cert_type.val();
			const data = new FormData();
			data.append( 'action', 'get_uo_groups' );
			if( 'group' === cert_type ){
				data.append( 'certificates', 'yes' );
			} else {
				data.append( 'certificates', 'no' );
			}
			data.append( 'uo_current_user', uoCertificates.uo_current_user_id );
			data.append( 'uo_cert_type', cert_type );
			this.$elements.filters.val_uo_cert_type.val( cert_type );

			// Add loading animation
			this.$elements.filters.typeWrapper.classList.add( 'ultp-download-certificates-filter--loading' );

			fetch(uoCertificates.rest_url+'get-groups-dropdown', {
				method: "POST",
				body: data
			})
				.then((response) => response.json())
				.then((data) => {

					// Remove loading animation
					this.$elements.filters.typeWrapper.classList.remove( 'ultp-download-certificates-filter--loading' );

					if ( ! data.success || ! Array.isArray( data.data ) || data.data.length === 0 ) {

						this.uoShowErrorBlock(
							this.$elements.filters.typeWrapper, 
							typeof data.msg !== 'undefined' ? data.msg : uoCertificates.i18n.empty
						);

						return;
					}

					// Show the child dropdown
					this.uoDropDownVisibility( cert_type );

					const groups = [];
					groups.push('<option value="">' + uoCertificates.groups_label + '</option>');
					data.data.forEach( group => groups.push( '<option value="'+group.value+'">'+group.text+'</option>' ) );
					this.$elements.filters.uo_groups.html( groups );
					if( cert_type === 'group' ){
						// this.$elements.filters.uo_groups.find('option').get(0).remove();
						// document.getElementById("uo_groups").options[0].remove();
						// this.uoShowBlock(this.$elements.filters.uo_submit_btn_html);
					}

					this.$elements.filters.uo_groups.select2();
				})
				.catch((error) => {
					// Remove loading animation
					this.$elements.filters.typeWrapper.classList.remove( 'ultp-download-certificates-filter--loading' );

					console.error(error);
				});

		});
	}

	getGroupsDropdown() {
		// Listen to changes on certificate type
		this.$elements.filters.uo_groups.on( 'change', ( e ) => {
			this.clearAllErrors();

			this.uoHideBlock( this.$elements.filters.uo_courses_block );
			this.uoHideBlock( this.$elements.filters.uo_quizzes_block );
			this.uoHideBlock( this.$elements.filters.uo_submit_btn_html );

			const cert_type = this.$elements.filters.uo_cert_type.val();
			const data = new FormData();
			var group_id = e.target.value;
			this.$elements.filters.val_uo_groups.val( group_id );

			if( '' === group_id ) {
				this.uoHideBlock(this.$elements.filters.uo_submit_btn_html);
				return;
			}

			if( 'group' === cert_type ) {
				this.uoShowBlock(this.$elements.filters.uo_submit_btn_html);
				return;
			}

			if( 'course' === cert_type ){
				data.append( 'certificates', 'yes' );
			} else {
				data.append( 'certificates', 'no' );
			}

			data.append( 'action', 'get_uo_courses' );
			data.append( 'group_id', group_id );
			data.append( 'uo_current_user', uoCertificates.uo_current_user_id );

			// Add loading animation
			this.$elements.filters.groupWrapper.classList.add( 'ultp-download-certificates-filter--loading' );

			fetch(uoCertificates.rest_url+'get-courses-dropdown', {
				method: "POST",
				body: data
			})
				.then((response) => response.json())
				.then((data) => {
					// Remove loading animation
					this.$elements.filters.groupWrapper.classList.remove( 'ultp-download-certificates-filter--loading' );

					if ( ! data.success || ! Array.isArray( data.data ) || data.data.length === 0 ) {

						this.uoShowErrorBlock(
							this.$elements.filters.uo_group_block, 
							typeof data.msg !== 'undefined' ? data.msg : uoCertificates.i18n.empty
						);

						return;
					}

					( 'course' === cert_type ? this.uoHideBlock( this.$elements.filters.uo_courses_block ) :  this.uoShowBlock( this.$elements.filters.uo_courses_block ) );

					const courses = [];
					courses.push( '<option value="">'+uoCertificates.course_label+'</option>' );
					data.data.forEach( course => courses.push( '<option value="'+course.ID+'">'+course.course_title+'</option>' ) );
					this.$elements.filters.uo_courses.html( courses );
					this.uoShowBlock( this.$elements.filters.uo_courses_block );
					this.$elements.filters.uo_courses.select2();
				})
				.catch((error) => {
					// Remove loading animation
					this.$elements.filters.groupWrapper.classList.remove( 'ultp-download-certificates-filter--loading' );

					console.error(error);
				});

			this.uoDropDownVisibility( cert_type );

		});
	}

	getCoursesDropdown() {
		// Listen to changes on certificate type
		this.$elements.filters.uo_courses.on( 'change', ( e ) => {
			this.clearAllErrors();
			
			this.uoHideBlock( this.$elements.filters.uo_quizzes_block );
			this.uoHideBlock( this.$elements.filters.uo_submit_btn_html );

			const cert_type = this.$elements.filters.uo_cert_type.val();
			const data = new FormData();
			var course_id = parseInt(e.target.value);
			var group_id = this.$elements.filters.val_uo_groups.val();
			this.$elements.filters.val_uo_courses.val( course_id );

			if( 'course' === cert_type ) {
				this.uoShowBlock(this.$elements.filters.uo_submit_btn_html);
				return;
			}

			data.append( 'action', 'get_uo_quizzes' );
			data.append( 'course_id', course_id );
			data.append( 'uo_current_user', uoCertificates.uo_current_user_id );

			if( 'quiz' === cert_type ) {
				data.append('certificates', 'yes');
			}

			// Add loading animation
			this.$elements.filters.courseWrapper.classList.add( 'ultp-download-certificates-filter--loading' );

			fetch(uoCertificates.rest_url+'get-quiz-dropdown', {
				method: "POST",
				body: data
			})
				.then((response) => response.json())
				.then((data) => {
					// Remove loading animation
					this.$elements.filters.courseWrapper.classList.remove( 'ultp-download-certificates-filter--loading' );

					if ( ! data.success || ! Array.isArray( data.data ) || data.data.length === 0 ) {

						this.uoShowErrorBlock(
							this.$elements.filters.courseWrapper, 
							typeof data.msg !== 'undefined' ? data.msg : uoCertificates.i18n.empty
						);

						return;
					}

					( 'quiz' === cert_type ? this.uoShowBlock( this.$elements.filters.uo_quizzes_block ) :  this.uoHideBlock( this.$elements.filters.uo_quizzes_block ) );
					
					const quizzes = [];
					quizzes.push( '<option value="">'+uoCertificates.quiz_label+'</option>' );
					if( 'quiz' === cert_type ){
						quizzes.push( '<option value="any">'+uoCertificates.any_quiz_label+'</option>' );
					}
					data.data.forEach( quiz => quizzes.push( '<option value="'+quiz.ID+'">'+quiz.quiz_title+'</option>' ) );
					this.$elements.filters.uo_quizzes.html( quizzes );
					this.$elements.filters.uo_quizzes.select2();
				})
				.catch((error) => {
					// Remove loading animation
					this.$elements.filters.courseWrapper.classList.remove( 'ultp-download-certificates-filter--loading' );
					
					console.error(error);
				});

		});
	}

	getQuizDropdown(){
		// Listen to changes on certificate type
		this.$elements.filters.uo_quizzes.on( 'change', ( e ) => {
			this.clearAllErrors();

			var quiz_id = parseInt(e.target.value);
			this.$elements.filters.val_uo_quizzes.val( quiz_id );

			if( '' !== e.target.value ){
				this.uoShowBlock(this.$elements.filters.uo_submit_btn_html);
			} else {
				this.uoHideBlock(this.$elements.filters.uo_submit_btn_html);
			}

		});
	}

	uoDropDownVisibility( cert_type = '' ) {
		if( '-' == cert_type ) {
			this.uoHideBlock( this.$elements.filters.uo_group_block );
			this.uoHideBlock( this.$elements.filters.uo_courses_block );
			this.uoHideBlock( this.$elements.filters.uo_quizzes_block );
		}
		this.uoShowBlock( this.$elements.filters.uo_group_block );
		this.uoHideBlock( this.$elements.filters.uo_courses_block );
		this.uoHideBlock( this.$elements.filters.uo_quizzes_block );
		this.uoHideBlock(this.$elements.filters.uo_submit_btn_html);
	}

	uoShowBlock( element ){
		element.classList.remove( 'ultp-download-certificates-filter--hidden' );
	}

	uoShowErrorBlock( $parent, message = '' ){
		if ( ! $parent ) {
			return;
		}

		if ( ! $parent.querySelector( '.ultp-download-certificates-dropdowns-error' ) ) {
			return;
		}

		$parent.querySelector( '.ultp-download-certificates-dropdowns-error' ).innerText = message;
	}

	clearAllErrors() {
		document.querySelectorAll( '.ultp-download-certificates-dropdowns-error' ).forEach( $wrapper => $wrapper.innerText = '' );
	}

	uoHideBlock( element ){
		element.classList.add( 'ultp-download-certificates-filter--hidden' );
	}

	/**
	 * Populates the groups dropdown
	 */
	populateGroupsDropdown() {

		// Do REST call to get the groups
		restCall(
			'get-groups-dropdown',

			// Data
			{
				action: 'get_uo_groups',
				certificates: this.$dropdownType.value == 'group' ? 'yes' : 'no',
				uo_current_user: uoCertificates.uo_current_user_id,
				type: this.$elements.filters.uo_cert_type.val(),
			},

			// On success
			( response ) => {
				// Check if it was successful
				this.populateDropdown( this.$dropdownGroup, response.data );
			},

			// On fail
			( e ) => {
				console.error( e );
			},
		);
	}

	/**
	 * Populates a dropdown
	 *
	 * @param {Node} $dropdown The select node
	 * @param {Array} options The options
	 * @param {Boolean} triggerChange Whether we should trigger the change event
	 */
	populateDropdown( $dropdown, options = [], triggerChange = true ) {
		// Remove all the options
		$dropdown.innerHTML = '';

		// Create the options
		options.forEach( option => $dropdown.insertAdjacentHTML( 'beforeEnd', `
			<option value="${ option.value }">
				${ option.text }
			</option>
		` ) );

		// Trigger change event
		if ( triggerChange ) {
			$dropdown.dispatchEvent( new Event( 'change' ) );
		}
	}

	/**
	 * Returns the "Type" dropdown
	 *
	 * @returns {Node} The type dropdown
	 */
	get $dropdownType() {
		return document.getElementById( 'ultp-download-certificates-type' );
	}

	/**
	 * Returns the "Group" dropdown
	 *
	 * @returns {Node} The groups dropdown
	 */
	get $dropdownGroup() {
		return document.getElementById( 'ultp-download-certificates-group' );
	}

	/**
	 * Returns the "Course" dropdown
	 *
	 * @returns {Node} The courses dropdown
	 */
	get $dropdownCourse() {
		return document.getElementById( 'ultp-download-certificates-course' );
	}

	/**
	 * Returns the "Quiz" dropdown
	 *
	 * @returns {Node} The quizzes dropdown
	 */
	get $dropdownQuiz() {
		return document.getElementById( 'ultp-download-certificates-quiz' );
	}

	/**
	 * Check if the page contains the Download Certificates shortcode
	 */
	get hasShortcode() {
		return isDefined( document.querySelector( '.ultp-download-certificates' ) );
	}
}

export default DownloadCertificates;
